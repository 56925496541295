<template>
    <u-btn-outlined-rounded v-bind="$attrs" color="grey lighten-3" @click="$emit('click')">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-btn-outlined-rounded>
</template>

<script>
import UBtnOutlinedRounded from "@/components/publish/styles/buttons/u-btn-outlined-rounded.vue";

export default {
    props: {},
    components: {
        UBtnOutlinedRounded,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-btn.grey--text.text--lighten-3{
    ::v-deep{
        .v-btn__content{
            color: var(--v-grey-base) !important;
        }
    }
}
</style>
