<template>
    <v-sheet :max-width="$vuetify.breakpoint.mdAndUp ? 448 : ''" class="ml-auto mb-12px" >
        <v-row class="row--xs">
            <v-col cols="12" md="auto">
                <select-primary :items="searchKeys" class="w-md-120px" @input="searchKey" />
            </v-col>
            <v-col cols="12" md="">
                <text-field-primary placeholder="Enter the search keyword." @input="searchValue">
                    <template v-slot:append>
                        <v-btn icon text color="grey darken-4" @click="$emit('search')"><icon-search /></v-btn>
                    </template>
                </text-field-primary>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import TextFieldPrimary from "@/components/publish/parents/forms/text-field-primary.vue";
import SelectPrimary from "@/components/publish/parents/forms/select-primary.vue";
import IconSearch from "@/components/publish/styles/icons/icon-search.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

export default {
    components: {
        TextFieldPrimary,
        SelectPrimary,
        IconSearch,
        BtnPrimary,
    },

    data() {
        return {
            searchKeys: [
                { text: "Total", value: null },
                { text: "Subject", value: "subject" },
                { text: "Subject + content", value: "subjectcontent" },
            ],
        };
    },
    methods: {
        searchKey(searchKey) {
            this.$emit("searchKey", searchKey);
        },
        searchValue(searchValue) {
            this.$emit("searchValue", searchValue);
        },
    },
};
</script>
