<template>
    <v-pagination v-bind="$attrs" class="v-pagination--text-underline" @input="search"></v-pagination>
    <!-- <v-pagination v-bind="$attrs" class="v-pagination--text-underline" @input="test"></v-pagination> -->
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {
        search(page){
            this.$emit('input', page)
        }
    },
};
</script>

<style lang="scss" scoped></style>
