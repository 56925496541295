<template>
    <u-text-field-underlined v-model="modelValue" v-bind="$attrs" @input="$emit('input', modelValue)">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-text-field-underlined>
</template>

<script>
import UTextFieldUnderlined from "@/components/publish/styles/forms/u-text-field-underlined.vue";

export default {
    props: {
        value: { type: String, default: null },
    },
    components: {
        UTextFieldUnderlined,
    },
    data() {
        return {
            modelValue: this.$props.value,
        };
    },
    watch: {
        value() {
            this.modelValue = this.value;
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
