var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._b({
    staticClass: "icon-svg icon-search",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : ''],
    attrs: {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M11.2671 5C7.80588 5 5 7.80588 5 11.2671C5 14.7283 7.80588 17.5341 11.2671 17.5341C12.8147 17.5341 14.2314 16.9731 15.3247 16.0434L18.2813 19L19 18.2813L16.0434 15.3247C16.9731 14.2314 17.5341 12.8147 17.5341 11.2671C17.5341 7.80588 14.7283 5 11.2671 5ZM6.01628 11.2671C6.01628 8.36715 8.36715 6.01628 11.2671 6.01628C14.167 6.01628 16.5179 8.36715 16.5179 11.2671C16.5179 14.167 14.167 16.5179 11.2671 16.5179C8.36715 16.5179 6.01628 14.167 6.01628 11.2671Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }